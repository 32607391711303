<template>
  <div class="mine">
    <div class="card-bg">
      <div class="card">
        <img :src="user.avatar" alt="" />
        <div class="info">
          <div class="name">{{ user.name }}</div>
          <div class="describe">{{ estate.roleName }}</div>
        </div>
      </div>
    </div>
    <div class="mine-cell" style="margin: 0px 16px; padding-top: 72px">
      <div>
        <van-cell v-if="proList" @click="switchType('switchPro')" center is-link size="large">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconqiehuanxiangmu"></use>
            </svg>
          </template>
          <template #title>
            <span class="custom-title">切换项目</span>
          </template>
          <template #default>
            <span class="custom-right">{{ estate.estateName }}</span>
          </template>
        </van-cell>
      </div>
      <van-cell-group style="margin-top: 12px" :border="false">
        <!--        名片二期-->
        <!--        <van-cell center is-link size="large" to="/myCard">-->
        <!--          <template #icon>-->
        <!--            <svg style="width: 16px; height: 16px; margin-right: 8px" class="icon" aria-hidden="true">-->
        <!--              <use xlink:href="#iconwodemingpian"></use>-->
        <!--            </svg>-->
        <!--          </template>-->
        <!--          <template #title>-->
        <!--            <span class="custom-title">我的名片</span>-->
        <!--          </template>-->
        <!--        </van-cell>-->

        <!--        来访平台这期暂时没做-->
        <!--        <van-cell v-if="user.showRoleName === '销售经理'&&url" center is-link size="large" :url="url">-->
        <!--          <template #icon>-->
        <!--            <svg-->
        <!--                style="width: 16px; height: 16px; margin-right: 8px"-->
        <!--                class="icon"-->
        <!--                aria-hidden="true"-->
        <!--            >-->
        <!--              <use xlink:href="#iconlaifangshujutongji"></use>-->
        <!--            </svg>-->
        <!--          </template>-->
        <!--          <template #title>-->
        <!--            <span class="custom-title">来访数据统计</span>-->
        <!--          </template>-->
        <!--        </van-cell>-->
        <van-cell @click="switchType('switchRole')" center is-link size="large">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconqiehuanjiaose"></use>
            </svg>
          </template>
          <template #title>
            <span class="custom-title">角色</span>
          </template>
          <template #default>
            <span class="custom-right">{{ estate.roleName }}</span>
          </template>
        </van-cell>
        <van-cell center is-link size="large" to="/mine/talkCode">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconwode_tuokema"></use>
            </svg>
          </template>
          <template #title>
            <span class="custom-title">我的拓客码</span>
          </template>
        </van-cell>
        <!--        来访平台这期暂时没做-->
        <van-cell center is-link size="large" to="/setting">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconshezhi"></use>
            </svg>
          </template>
          <template #title>
            <span class="custom-title">设置</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <h5-navigater ref="navigater" />
    <!--    项目弹窗-->
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action get-container="#app" @select="selectProj" />
  </div>
</template>

<script>
import login from '@/api/login';
import { Toast } from 'vant';

export default {
  name: 'mine',
  data() {
    return {
      type: 'switchRole',
      proList: [],
      url: '',
      show: false,
      actions: [],
      user: {},
      estate: {
        estateName: '',
        estateId: '',
        roleName: '',
        saleUserId: '',
      },
    };
  },
  methods: {
    //选择项目或者角色
    async selectProj(val) {
      let res;
      switch (this.type) {
        case 'switchPro':
          this.estate.estateName = val.name;
          this.estate.estateId = val.id;
          this.estate.roleName = val.roleName;
          this.estate.saleUserId = val.saleUserId;
          localStorage.setItem('platform_estateId', val.id);
          localStorage.setItem('platform_estateName', val.name);
          //获取应用权限
          res = await login.appAuthority({ estateId: localStorage.getItem('platform_estateId') });
          if (res.code === '0') {
            let appPermit = res.data.map((item) => item.permit);
            localStorage.setItem('platform_appPermit', JSON.stringify(appPermit));
            localStorage.setItem('platform_appNews', JSON.stringify(res.data));
          }
          break;
        case 'switchRole':
          this.estate.roleName = val.name;
          this.estate.saleUserId = val.id;
          break;
      }
      localStorage.setItem(
        'platform_role',
        JSON.stringify({
          saleRoleName: this.estate.roleName,
          saleUserId: this.estate.saleUserId,
          name: this.user.name,
        })
      );
      this.$refs.navigater.getAppPermit();
      Toast('切换成功');
    },
    //切换项目或者角色
    async switchType(type) {
      this.actions = [];
      this.type = type;
      switch (type) {
        case 'switchPro':
          this.proList.map((item) => {
            let index = item.roleAuthorities.findIndex((item) => item.saleRoleName === this.estate.roleName);
            if (index !== -1) {
              this.actions.push({
                name: item.estateName,
                id: item.estateId,
                color: item.estateId === this.estate.estateId ? '#3A74F2' : '',
                roleName: item.roleAuthorities[index].saleRoleName,
                saleUserId: item.roleAuthorities[index].saleUserId,
              });
            } else {
              this.actions.push({
                name: item.estateName,
                id: item.estateId,
                color: item.estateId === this.estate.estateId ? '#3A74F2' : '',
                roleName: item.roleAuthorities[0].saleRoleName,
                saleUserId: item.roleAuthorities[0].saleUserId,
              });
            }
          });
          break;
        case 'switchRole':
          this.proList.map((item) => {
            if (item.estateId === this.estate.estateId) {
              item.roleAuthorities.map((items) => {
                this.actions.push({
                  name: items.saleRoleName,
                  id: items.saleUserId,
                  color: items.saleRoleName === this.estate.roleName ? '#3A74F2' : '',
                });
              });
            }
          });
          break;
      }
      this.show = true;
    },
    //获取项目信息
    async authority() {
      let opId = localStorage.getItem('platform_opId');
      let { code, data } = await login.authority({ opId });
      if (code === '0') {
        this.proList = data;
      }
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('platform_user'));
    this.estate.estateName = localStorage.getItem('platform_estateName');
    this.estate.estateId = localStorage.getItem('platform_estateId');
    this.estate.roleName = JSON.parse(localStorage.getItem('platform_role')).saleRoleName;
    this.authority();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/') {
      //必要的
      this.$wx.closeWindow();
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.mine {
  .card-bg {
    height: 44px;
    background-color: #3974c6;
    position: relative;

    .card {
      padding: 16px;
      top: 12px;
      left: 16px;
      width: 311px;
      position: absolute;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: @white;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.04);

      .info {
        margin-left: 13px;

        .name {
          color: @gary-wxb-black-1;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }

        .describe {
          color: @gary-wxb-gray-3;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
      }

      img {
        width: 48px;
        height: 48px;
        border: 1px solid #eeeeee;
        border-radius: 48px;
      }
    }
  }
  .mine-cell {
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
    .van-cell-group {
      border-radius: 4px;
    }
    .van-cell {
      padding: 14px 8px 14px 12px;
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .custom-title {
    color: @gary-wxb-black-1;
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
  }

  .custom-right {
    color: @gary-wxb-gray-3;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }
}
</style>
<style lang="less">
.follow-btn {
  .van-dialog__confirm {
    background: #f0f4fe;

    &:active {
      background: #f0f4fe;
    }
  }
}
</style>
